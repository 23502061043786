import request from '@/axios/index';

// 首页-根据二级类Id进入题库首页
export function getQuestionBank(secondId) {
    return request({
        url: '/api/app/v1/test_paper/home',
        method: 'post',
        params: {secondId: secondId}
    })
}

// 首页-题库一二级类列表
export function firstOrSecondList(params) {
    return request({
        url: '/api/app/v1/test_paper/type/firstOrSecondList',
        method: 'post',
        params: params
    })
}

// 首页-题库二级类列表
export function second_list(firstId) {
    return request({
        url: '/api/app/v1/test_paper/type/second_list',
        method: 'post',
        params: {firstId: firstId}
    })
}

// 首页-排行榜
export function ranking_list(secondId) {
    return request({
        url: '/api/app/v1/test_paper/home/ranking_list',
        method: 'post',
        params: {secondId: secondId}
    })
}

// 是否第一次选择学习方向
export function isFirstSelect(secondId) {
    return request({
        url: '/api/frame/v1/customer/select/id',
        method: 'post',
        params: {secondId: secondId}
    })
}

// 获取学习方向的接口
export function getQuestionList(secondId) {
    return request({
        url: '/api/app/v1/index/course/type/list',
        method: 'post',
        params: {secondId: secondId}
    })
}

// 获取学习方向的接口
export function setFirstTypeId(firstTypeId) {
    return request({
        url: '/api/app/v1/course/package/edit/firstTypeId',
        method: 'post',
        params: {firstTypeId: firstTypeId}
    })
}

// 章节练习
export function getExerciseList(data) {
    return request({
        url: '/api/app/v1/test_paper/exercise/list',
        method: 'post',
        params: data
    })
}

// 做题记录 试卷
export function getChapterList(secondId) {
    return request({
        url: '/api/app/v1/exercise_subject_eecord/chapter/list',
        method: 'post',
        params: {secondId: secondId}
    })
}

// 做题记录 章节
export function getPagerList(secondId) {
    return request({
        url: '/api/app/v1/exercise_subject_eecord/paper/list',
        method: 'post',
        params: {secondId: secondId}
    })
}

// 章节-答题获取题(重做)
export function getErrorSubjectExercise(nodeId) {
    return request({
        url: '/api/app/v1/error_subject/exercise/subjectList',
        method: 'post',
        params: {nodeId: nodeId}
    })
}

// 试卷-答题获取题(重做)
export function getErrorSubjectpaper(testPaperId) {
    return request({
        url: '/api/app/v1/error_subject/paper/subjectList',
        method: 'post',
        params: {testPaperId: testPaperId}
    })
}

// 章节/专项练习-答题获取题列表
export function getTestPaperSubjectList(data) {
    return request({
        url: '/api/app/v1/test_paper/exercise/subjectList',
        method: 'post',
        params: data
    })
}

//做题记录试卷获取对应题
export function getTestPaperList(data) {
    return request({
        url: '/api/app/v1/test_paper/paper/subjectList',
        method: 'post',
        params: data
    })
}

//章节练习--答题报告
export function getEexerciseAssignment_paper(data) {
    return request({
        url: '/api/app/v1/test_paper/exercise/assignment_paper',
        method: 'post',
        params: data
    })
}

//历年真题、考前押题、模拟试卷--答题报告
export function getPaperAssignment_paper(data) {
    return request({
        url: '/api/app/v1/test_paper/paper/assignment_paper',
        method: 'post',
        params: data
    })
}

//历年真题、考前押题、模拟试卷--错题解析
export function subjectAnalysisList(data) {
    return request({
        url: '/api/app/v1/test_paper/paper/subject/analysisList',
        method: 'post',
        params: data
    })
}

//章节、专项--错题解析
export function exerciseSubjectAnalysisList(data) {
    return request({
        url: '/api/app/v1/test_paper/exercise/subject/analysisList',
        method: 'post',
        params: data
    })
}
