<template>
  <div class="chapterDetail">
    <div v-if="show&&!isCard">
      <examinationHeader ref="examinationHeader" :showAnalysis="showAnalysis" :paperAnswerTime="paperAnswerTime" :id="id" :type="type" @jump="jump" @headerClick="headerClick"></examinationHeader>
      <chapterDetailCom ref="chapterDetailCom" :AnswerList="AnswerList" :isNext="isNext" :isAnaly="isAnaly_val"
           @allEventFn="allEventFn" :fontType="fontType"></chapterDetailCom>
    </div>

    <div v-if="isCard">
      <answerCard @hideCard="hideCard" @headerClick="headerClick"></answerCard>
    </div>
  </div>
</template>

<script>

import chapterDetailCom from '../chapter/chapterDetail.vue';
import examinationHeader from '../examinationHeader/examinationHeader.vue';
import { getTestPaperList, getTestPaperSubjectList} from "@/api/questionBank";
import {pape_save_subject,save_subject,assignment_paper,test_paper_paper} from "@/api/chapter.js";
import answerCard from "@/pages/study/answerCard/answerCard";
export default {
  components: {
    chapterDetailCom,
    examinationHeader,
    answerCard
  },
  data() {
    return {
      AnswerList: {},
      isNext: false,
      isCard: false,
      show: false,
      showAnalysis: true,
      fontType: 'in',
      isAnaly_val:'no',
      paperAnswerTime: '',
      id:'',
      type:'',
    }
  },
  name: 'detail',
  created() {
    this.init();
  },
  methods: {
    init() {
      this.id = this.$route.query.id;
      this.paperAnswerTime = '';
      if(this.$route.query.tab === '1') {
        this.type = 'test';
        let param = {
          testPaperId: this.$route.query.id,
          type: 2
        };
        getTestPaperList(param).then((res) => {
          this.show = true;
          this.AnswerList = res.data.data;
          this.paperAnswerTime = res.data.data.paperAnswerTime;
          this.$refs.chapterDetailCom.setSwiptTo(parseInt(this.AnswerList.completeNumber));
        })
        this.showAnalysis = false;
      }else {
        let param = {
          nodeId: this.$route.query.id,
          nodeType: 1,
          type: 1
        };
        this.showAnalysis = true;
        getTestPaperSubjectList(param).then((res) => {
          this.show = true;
          this.AnswerList = res.data.data;
          this.AnswerList.paperSubjectList = res.data.data.paperChapterSubjectList;
          this.$refs.chapterDetailCom.setSwiptTo(parseInt(this.AnswerList.completeNumber));
        })
      }
    },
    hideCard(item) {
      this.isCard = false;
      if(item) {
        this.$refs.chapterDetailCom.setSwiptTo(parseInt(item.index));
      }
    },
    getAnswerTimeLength(times){
        let arys = times.split(":");
        let time1  = parseInt(arys[0])*60*60;
        let time2  = parseInt(arys[1])*60; 
        let time3  = parseInt(arys[2]);
        return time1+time2+time3; 
    },
    allEventFn(typeObj,res,index){
      if(typeObj.type=='radio'){
        let answerTimeLength = this.getAnswerTimeLength(this.$store.getters.getAnswerTime.times);
        if(this.$route.query.tab === '0'){
          save_subject(answerTimeLength,'1',res.subjectId,res.answerId,"").then((res)=>{ });
        }else{
          pape_save_subject(answerTimeLength,this.$route.query.id,res.subjectId,res.answerId,"","").then((res)=>{});
        }
      }else if(typeObj.type =='checkbox'){
        let answerTimeLength = this.getAnswerTimeLength(this.$store.getters.getAnswerTime.times);
        let answerIds = [];
        res.map((item)=>{ answerIds.push(item.answerId) });
        if(this.$route.query.tab === '0'){
          save_subject(answerTimeLength,'1',res[0].subjectId,answerIds,"").then((res)=>{})
        }else{
          pape_save_subject(answerTimeLength,this.$route.query.id,res[0].subjectId,answerIds,"","").then((res)=>{})
        }
      }else if(typeObj.type =='indeter'){
        let ansList = res[index];
        let answerTimeLength = this.getAnswerTimeLength(this.$store.getters.getAnswerTime.times);
        let answerIds = [];
        ansList.map((item)=>{ answerIds.push(item.answerId) });
        if(this.$route.query.tab === '0'){
          save_subject(answerTimeLength,'1',ansList[0].subjectId,answerIds,"").then((result)=>{})
        }else{
          pape_save_subject(answerTimeLength,this.$route.query.id,ansList[0].subjectId,answerIds,"","").then((result)=>{})
        }
        
      }else if(typeObj.type =='cal'){
        let ansList = res[index];
        let answerTimeLength = this.getAnswerTimeLength(this.$store.getters.getAnswerTime.times);
        if(this.$route.query.tab === '0'){
          save_subject(answerTimeLength,'1',ansList.subjectId,"",ansList.doneAnswer).then((res)=>{});
        }else{
          pape_save_subject(answerTimeLength,this.$route.query.id,ansList.subjectId,"",ansList.doneAnswer,ansList.score).then((res)=>{});
        }
      }
    },
    jump() {
      if(this.$route.query.tab === '1') {
        test_paper_paper(this.$route.query.id).then((res)=>{
          if(res.data.code ==0){
            this.$router.push({
              path:"/answerReport",
              query:{
                id:this.$route.query.id,
                path:"/questionRecordDetail",
                tab: this.$route.query.tab,
                type: 'testPaper'
              }
            })
          }
        })

      }else {
        assignment_paper(this.$route.query.id,1).then((res)=>{
          if(res.data.code == 0){
            this.$router.push({
              path:"/answerReport",
              query:{
                id:this.$route.query.id,
                path:"/questionRecordDetail",
                tab: this.$route.query.tab,
                type: 'chapterExercises'
              }
            })
          }
        })

      }
    },
    headerClick(item) {
      console.log(item);
      if (item.type === 'automaticNext') {
        // 自动下一题
        this.isNext = item.value;
      } else if (item.type === 'fontSize') {
        // 切换字体大小
        this.fontType = item.value;
      } else if (item.type === 'hand') {
        // 交卷
        this.$refs.examinationHeader&&this.$refs.examinationHeader.hideHand();
        if(!item.isJump) {
          if(this.$route.query.tab === '1') {
            test_paper_paper(this.$route.query.id).then((res)=>{
              if(res.data.code ==0){
                this.$router.push({
                  path:"/answerReport",
                  query:{
                    id:this.$route.query.id,
                    path:"/questionRecordDetail",
                    tab: this.$route.query.tab,
                    type: 'testPaper'
                  }
                })
              }
            })

          }else {
            assignment_paper(this.$route.query.id,1).then((res)=>{
              if(res.data.code == 0){
                this.$router.push({
                  path:"/answerReport",
                  query:{
                    id:this.$route.query.id,
                    path:"/questionRecordDetail",
                    tab: this.$route.query.tab,
                    type: 'chapterExercises'
                  }
                })
              }
            })

          }
        }
      } else if (item.type === 'stop') {
        // 停止做题
        this.$refs.examinationHeader.hideHand();
      } else if (item.type === 'answerCard') {
        // 答题卡
        this.isCard = true;
      } else if (item.type === 'analysis') {
        // 答案解析
        // 答案解析
        let indexs = this.$refs.chapterDetailCom.getCurnet();
        this.$store.commit('setQuestionSwipeIndex',{index:indexs});
        this.isAnaly_val = 'yes';

      }
    }

  }
}
</script>
<style lang="less" scoped>
.chapterDetail {
  width: 100%;
  background: #f5f5f5;
  height: 100vh;
}
</style>
