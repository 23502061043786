<template>
  <div class="warp">
    <div class="header">
      <van-nav-bar fixed @click-left="goBack" left-arrow title="答题卡"></van-nav-bar>
    </div>

    <div class="bodyWarp">
      <div class="title">
        <span><i class="answered"></i>已答题{{ statistics.answered }}道</span>
        <span><i class="noAnswered"></i>未答题{{ statistics.noAnswered }}道</span>
        <span><i class="sign"></i>标记{{ statistics.sign }}道</span>
      </div>

      <div class="warp">
        <template v-for="item in list">
          <p>{{ item.name }}</p>
          <template v-for="(val,index) in item.children">
            <span v-if="val.doneAnswerIds||val.doneAnswer" class="answered" @click="jump(val)">{{ (index+1) }}</span>
            <span v-if="!val.doneAnswerIds&&!val.doneAnswer" class="noAnswered" @click="jump(val)">{{ (index+1) }}</span>
            <span v-if="val.isSign === '1'" class="sign" @click="jump(val)">{{ (index+1) }}</span>
          </template>
        </template>
      </div>

      <div class="subWarp" v-if="showBtn">
        <van-button type="info" class="submite" @click="submite">交卷</van-button>
      </div>

    </div>

  </div>
</template>

<script>

import {
  exerciseSubjectAnalysisList,
  getErrorSubjectExercise,
  getErrorSubjectpaper,
  getTestPaperList,
  getTestPaperSubjectList, subjectAnalysisList
} from "@/api/questionBank.js";

export default {
  data() {
    return {
      list: [],
      typeList: [
        {k: '1', name: '单选题'},
        {k: '2', name: '多项选择题'},
        {k: '3', name: '判断题'},
        {k: '4', name: '不定项选择题'},
        {k: '5', name: '计算分析题'},
        {k: '6', name: '综合题'},
        {k: '7', name: '简答题'},
        {k: '8', name: '计算回答题'},
        {k: '9', name: '案例分析题'},
        {k: '10', name: '计算题'},
        {k: '11', name: '综合分析题'}
      ],
      statistics: {
        answered: 0,
        noAnswered: 0,
        sign: 0,
      },
      showBtn: true
    }
  },
  name: 'answerCard',
  methods: {
    goBack(item) {
      this.$emit('hideCard',item)
    },
    jump(item) {
      this.goBack(item);
    },
    submite(item) {
      this.$emit('headerClick', {type: 'hand'});
      this.goBack();
    },
    init() {
      this.showBtn = true;
      if (this.$route.query.type === 'test') {
        let param = {
          testPaperId: this.$route.query.id
        };
        getTestPaperList(param).then((res) => {
          this.consolidateData(res.data.data);
        })
      } else {
        if(this.$route.query.question === 'errorBook') {
          if (this.$route.query.tab === '1') {
            getErrorSubjectpaper(this.$route.query.id).then((res) => {
              res.data.data.completeNumber = res.data.data.length;
              res.data.data.countNumber = res.data.data.length;
              res.data.data.paperChapterSubjectList = res.data.data;
              this.consolidateData(res.data.data);
            })
          }else {
            getErrorSubjectExercise(this.$route.query.id).then((res) => {
              res.data.data.completeNumber = res.data.data.length;
              res.data.data.countNumber = res.data.data.length;
              res.data.data.paperChapterSubjectList = res.data.data;
              this.consolidateData(res.data.data);
            })
          }
        }else if(this.$route.query.question === 'chapterExercises') {
          this.showBtn = false;
          let params = {
            nodeId: this.$route.query.id,
            nodeType: '1',
            type: this.$route.query.type === 'error' ? 1 : 2
          };
          exerciseSubjectAnalysisList(params).then(res=>{
            let arr = res.data.data.filter(v=>v.doneAnswerIds||v.doneAnswer)
            let data = {
              paperChapterSubjectList: res.data.data,
              countNumber: arr.length,
              completeNumber: res.data.data.length
            }
            this.consolidateData(data);
          })
        }else if(this.$route.query.question === 'testPaper') {
          this.showBtn = false;
          let params = {
            testPaperId: this.$route.query.id,
            type: this.$route.query.type === 'error' ? 1 : 2
          };
          subjectAnalysisList(params).then(res=>{
            let arr = res.data.data.filter(v=>v.doneAnswerIds||v.doneAnswer)
            let data = {
              paperChapterSubjectList: res.data.data,
              countNumber: arr.length,
              completeNumber: res.data.data.length
            }
            this.consolidateData(data);
          })
        }else {
          let param = {
            nodeId: this.$route.query.id,
            nodeType: 1,
            type: 1
          };
          getTestPaperSubjectList(param).then((res) => {
            this.consolidateData(res.data.data);
          })
        }
      }
    },
    consolidateData(data) {
      this.list = [];
      this.statistics.answered = data.completeNumber;
      this.statistics.noAnswered = data.countNumber - data.completeNumber;
      let curList = this.$route.query.type === 'test'?data.paperSubjectList:data.paperChapterSubjectList;
      let isSign = [];

      curList.forEach((v,i) => {
        if(v.isSign === '1') {
          isSign.push(v);
        }
        v['index'] = i;
      });
      this.statistics.sign = isSign.length;

      this.typeList.map(v => {
        let paper = curList.filter(val => val.classify === v.k);
        if (paper.length) {
          this.list.push({name:v.name,children:paper});
        }
      })
    }
  },
  created() {
    this.init();
  }
}
</script>

<style lang="less" scoped>
.header {
  font-size: 17px;
  font-weight: 500;
  color: #141414;
  line-height: 24px;
}

/deep/ .van-nav-bar__title {
  font-size: 17px;
  font-weight: 500;
  color: #141414;

}

/deep/ .header .van-icon-arrow-left {
  color: #000;
}

.bodyWarp {
  margin-top: 42px;
  padding: 0 0 0 14px;

  .title {
    height: 47px;
    line-height: 47px;
    border-bottom: 1px solid #DDDDDD;

    span {
      margin-right: 25px;

      i {
        margin-right: 7px;
        width: 10px;
        height: 10px;
        display: inline-block;
        border-radius: 50%;
      }

      .answered {
        background: #5F7DFF;
      }

      .noAnswered {
        background: #D6DEFF;
      }

      .sign {
        border: 1px solid #E8611F;
      }
    }
  }

  .warp {
    overflow: auto;
    height: calc(100vh - 178px);

    p {
      height: 22px;
      font-size: 15px;
      font-weight: 500;
      color: #141414;
      line-height: 22px;
      margin-top: 10px;
    }

    span {
      width: 29px;
      height: 29px;
      text-align: center;
      line-height: 29px;
      border-radius: 50%;
      display: inline-block;
      margin: 10px 18px 10px 0;
    }

    .noAnswered, .answered {
      color: #fff;
    }

    .answered {
      background: #5F7DFF;
    }

    .noAnswered {
      background: #D6DEFF;
    }

    .sign {
      border: 1px solid #E8611F;
      color: #E8611F;
    }
  }

  .subWarp {
    position: fixed;
    bottom: 0;
    background: #fff;
    padding: 20px 0;
    width: calc(100% - 28px);

    .submite {
      background: #5F7DFF;
      border-radius: 5px;
      height: 40px;
      width: 100%;
    }
  }
}
</style>
