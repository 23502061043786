<template>
  <div class="warp">
    <div class="header">
      <van-nav-bar @click-left="goBack" left-arrow>
        <template #right>
          <template v-for="item in list">
          <span class="btns" :class="item.onlyIcon" :key="item.type" @click="clickType(item)">
            <img v-if="item.name" :src="item.src">
            <p v-if="item.name">{{ item.name }}</p>
            <van-popover :offset="[10,8]" v-if="!item.name" v-model="showPopover" placement="bottom-end"
                         trigger="click">
              <div class="popover">
              <div @click="calculator"><img :src="jsq">计算器</div>
              <div>
                <span class="fon" :class="active==='max'?'active':''" @click="fonChang('max')">大</span>
                <span class="fon" :class="active==='in'?'active':''" @click="fonChang('in')">中</span>
                <span class="fon" :class="active==='min'?'active':''" @click="fonChang('min')">小</span>
              </div>
              <div>自动下一题 <span><van-switch size="16px" class="swith" @change="changeSwith"
                                           v-model="checked"/></span></div>
              </div>
                <template #reference>
                    <img :src="item.src">
                </template>
            </van-popover>
          </span>
          </template>
        </template>
      </van-nav-bar>
    </div>

    <div class="bodyWarp">
      <van-popup v-model="show" round :close-on-click-overlay="false">
        <div class="popupSun">
          <p>温馨提示</p>
          <p class="msg">您已经开始做题，请选择</p>
          <div class="btn" @click="btnClick('hand')">交卷</div>
          <div class="btn" @click="btnClick('stop')">停止做题</div>
          <div class="btn" @click="btnClick('continue')">继续做题</div>
        </div>
      </van-popup>
      <van-popup v-model="showCalculator" class="calculatorWarp" position="bottom">
        <div class="computeResult">{{ formulaStr }}</div>
        <div class="num">
          <span v-for="(item,i) in numList" :class="(i+1)%3===0?'left0':''" :key="item" @click="keyboard(item)">{{
              item
            }}</span>
        </div>
        <div class="formula">
          <span v-for="(item,i) in formulaList" class="span" :class="[(i+1)%3===0?'left0':'',item==='C'?'c':'','span']"
                :key="item" @click="keyboard(item)">{{ item }}</span>
        </div>
        <div class="footer">
          <span class="w retract" @click="hideCalculator">收起计算器</span>
          <span @click="keyboard(0)">0</span>
          <span @click="keyboard('.')">.</span>
          <span class="w res" @click="keyboard('=')">=</span>
        </div>
      </van-popup>
    </div>

  </div>
</template>

<script>

export default {

  props: ['showAnalysis','answerCard', 'paperAnswerTime', 'showTime', 'id','type','modleType'],
  data() {
    return {
      list: [
        {
          name: '00:00:00',
          src: require('@/assets/all_Icon/drawable-xxhdpi/question_js.png'),
          type: 'time'
        },
        {
          name: '答案解析',
          src: require('@/assets/all_Icon/drawable-xxhdpi/question_jx.png'),
          type: 'analysis'
        },
        {
          name: '答题卡',
          src: require('@/assets/all_Icon/drawable-xxhdpi/question_dtk.png'),
          type: 'answerCard'
        },
        {
          name: '交卷',
          src: require('@/assets/all_Icon/drawable-xxhdpi/question_jj.png'),
          type: 'handInPapers'
        },
        {
          name: '',
          src: require('@/assets/all_Icon/drawable-xxhdpi/question_more.png'),
          type: 'message',
          class: 'onlyIcon'
        }
      ],
      time: 0,
      timerFn: null,
      secondId: '',
      show: false,
      jsq: require('@/assets/all_Icon/drawable-xxhdpi/question_more_jsq.png'),
      showPopover: false,
      checked: false,
      showCalculator: false,
      active: 'in',
      formulaStr: '',
      numList: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      formulaList: ['%', '←', 'C', '+', '-', '(', 'x', '÷', ')']   
    }
  },
  name: 'examinationHeader',
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    clickType(item) {
      switch (item.type) {
        case 'analysis':
          // 答案解析
          this.$store.commit('setResolution_method',{key:'header_res'});
          this.$emit('headerClick', {type: 'analysis', isAnaly: 'yes'});
          break;
        case 'answerCard':
          this.$emit('headerClick', {type: 'answerCard'});
          // this.$router.push({name: 'answerCard', query: {id: this.id,type:this.type,modleType:this.modleType}});
          break;
        case 'handInPapers':
          this.show = true;
          break;
      }
    },
    btnClick(type) {
      if (type === 'hand') {
        // 交卷
        this.$emit('headerClick', {type: 'hand'});
      } else if (type === 'stop') {
        // 停止做题
        this.$emit('headerClick', {type: 'stop'});
      } else if (type === 'continue') {
        // 继续做题
        this.hideHand();
      }
    },
    hideHand() {
      this.show = false;
    },
    timer() {
      this.timerFn = setTimeout(() => {
        this.time += 1;
        this.list[0].name = this.formatSeconds(this.time);
        this.$store.commit('setAnswerTime', {times: this.formatSeconds(this.time)});
        this.timer();
      }, 1000);
    },
    formatSeconds(value) {
      let res = parseInt(value);
      let h = Math.floor(res / 3600) < 10 ? '0' + Math.floor(res / 3600) : Math.floor(res / 3600);
      let m = Math.floor(res / 60 % 60) < 10 ? '0' + Math.floor(res / 60 % 60) : Math.floor(res / 60 % 60);
      let s = Math.floor(res % 60) < 10 ? '0' + Math.floor(res % 60) : Math.floor(res % 60);

      let str = '';
      str += `${h}:`;
      str += `${m}:`;
      str += s;
      return str;
    },
    // 时间格式化
    toDub(n) {
      //补0操作
      if (n < 10) {
        return "0" + n;
      } else {
        return "" + n;
      }
    },
    calculator() {
      console.log('计算器');
      this.formulaStr = '';
      this.showCalculator = true;
    },
    hideCalculator() {
      this.formulaStr = '';
      this.showCalculator = false;
    },
    changeSwith(val) {
      console.log(val);
      this.$emit('headerClick', {type: 'automaticNext', value: val});
    },
    fonChang(type) {
      console.log(type);
      this.active = type;
      this.$emit('headerClick', {type: 'fontSize', value: type});
    },
    keyboard(item) {
      if (this.formulaStr.indexOf('=') > -1) {
        this.formulaStr = '';
      }
      if (item === '←') {
        this.formulaStr = this.formulaStr.substring(0, this.formulaStr.length - 1);
      } else if (item === '=') {
        let str = this.formulaStr;
        let res = eval(str.replace(/x/g, '*').replace(/÷/g, '/').replace(/%/g, '/100'));
        this.formulaStr = `${this.formulaStr}=${res}`;
      } else if (item === 'C') {
        this.formulaStr = '';
      } else {
        this.formulaStr += item.toString();
      }
    },
    //倒计时
    countDown() {
      this.timerFn = setTimeout(() => {
        this.time -= 1;
        this.list[0].name = this.formatSeconds(this.time);
        this.$store.commit('setAnswerTime', {times: this.formatSeconds(this.time)});
        if (this.time <= 0) {
          this.$emit('headerClick', {type: 'hand',isJump:true});
          this.$dialog.alert({
            title: '温馨提示',
            message: '考试时间已到，系统已自动交卷',
            confirmButtonText: '查看结果',
            theme: 'round-button',
            confirmButtonColor: '#07BDC8'
          }).then(() => {
            this.$emit('jump');
          });
        }else {
          this.countDown();
        }
      }, 1000);
    }
  },
  created() {
    if (!this.showAnalysis) {
      this.list = this.list.filter(v => {
        return v.type !== 'analysis';
      })
    }
    if (this.answerCard) {
      this.list = this.list.filter(v => {
        return v.type === 'answerCard';
      })
    }
    this.secondId = window.localStorage.getItem('secondId');
  },
  mounted() {
    if(!this.showTime) {
      if (parseInt(this.paperAnswerTime)) {
        this.time = this.paperAnswerTime*60;
        this.list[0].name = this.formatSeconds(this.time);
        this.countDown();
      } else {
        this.timer();
      }
    }
  },
  beforeDestroy() {
    clearTimeout(this.timerFn);
    this.timerFn = null;
  }
}
</script>

<style lang="less" scoped>
.header {
  font-size: 17px;
  font-weight: 500;
  color: #141414;
  line-height: 24px;
}

/deep/ .van-nav-bar__title {
  font-size: 17px;
  font-weight: 500;
  color: #141414;

}

/deep/ .header .van-icon-arrow-left {
  color: #000;
}

/deep/ .van-nav-bar__left:active, /deep/ .van-nav-bar__right:active {
  opacity: 1 !important;
}

.btns {
  margin-right: 6px;
  text-align: center;

  img {
    width: 14px;
    height: 14px;
  }

  p {
    height: 13px;
    font-size: 12px;
    font-weight: 400;
    color: #888888;
    line-height: 13px;
    transform: scale(0.8)
  }
}

.onlyIcon {
  margin-right: 0;
  line-height: 35px;
}

.popupSun {
  width: 217px;
  height: 190px;
  background: #FFFFFF;
  padding-top: 18px;
  text-align: center;

  p {
    height: 22px;
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    line-height: 22px;
  }

  .msg {
    margin-top: 11px;
    height: 19px;
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    line-height: 19px;
    margin-bottom: 21px;
  }

  .btn {
    border-top: 1px solid #E5E5E5;;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    font-weight: 500;
    color: #5F7DFF;
  }
}

.popover {
  font-size: 12px;

  div {
    height: 28px;
    line-height: 28px;
    text-align: center;
    width: 130px;

    .swith {
      font-size: 14px;
      width: 30px;
      height: 15px;
      vertical-align: text-bottom;
    }

    img {
      vertical-align: sub;
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }

    .fon {
      width: 30px;
      height: 18px;
      line-height: 18px;
      border: 1px solid #ddd;
      color: #3A3A3A;
      display: inline-block;
    }

    .fon:first-child {
      border-right: none;
    }

    .fon:last-child {
      border-left: none;
    }

    .active {
      background: #5D7DFF;
      color: #fff;
    }
  }
}

.calculatorWarp {
  padding: 10px;
  box-sizing: border-box;
  height: 320px;
  font-size: 20px;

  .computeResult {
    margin: 10px 10px 5px;
    height: 40px;
    line-height: 40px;
    background: #F8F8F8;;
    border-radius: 5px;
    text-align: right;
  }

  .formula, .num {
    width: 50%;
    text-align: center;
    display: inline-block;

    span {
      display: inline-block;
      margin: 5px 5px 5px 0;
      width: 50px;
      height: 50px;
      line-height: 50px;
      background: #F8F8F8;
      border-radius: 5px;
    }

    .span {
      background: #DADBE0;
    }

    .c {
      background: #F8AB50;
    }

    .left0 {
      margin-right: 0;
    }
  }

  .footer {
    text-align: center;

    span {
      display: inline-block;
      margin: 5px 5px 5px 0;
      width: 50px;
      height: 50px;
      line-height: 50px;
      background: #F8F8F8;
      border-radius: 5px;
    }

    .res {
      background: #5D7DFF;
      color: #fff;
    }

    .w {
      width: 110px;
    }

    .retract {
      font-size: 16px;
    }
  }
}

</style>
